








import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator';
import Links from '@/components/AboutMe/Links.vue';
import Skills from '@/components/AboutMe/Skills.vue';
import WhoAmI from '@/components/AboutMe/WhoAmI.vue';

@Component({
  components: {
    Links,
    Skills,
    WhoAmI,
  },
})
export default class AboutMe extends Vue {
  @InjectReactive() prefersReducedMotion!: boolean;
  smallScreen = false;
  slides: string[] = ['who-am-i', 'skills', 'links'];
  curSlide = 0;
  transitionActive = false;

  private _resizeObserver!: ResizeObserver;
  private _wheelListener!: (event: WheelEvent) => void;

  get fullScreenSlides(): boolean {
    return !this.smallScreen && !this.prefersReducedMotion;
  }

  get curSlideName(): string {
    return this.slides[this.curSlide];
  }

  created(): void {
    // resize observer
    this._resizeObserver = new ResizeObserver(() => {
      this.smallScreen = (window.innerWidth < 800 || window.innerHeight < 700);
    });
    this._resizeObserver.observe(document.body);

    // wheel
    // TODO: Fix for laptop
    this._wheelListener = (event: WheelEvent) => {
      if (this.transitionActive) return;
      if (event.deltaY > 0) {
        this.curSlide = Math.min(this.slides.length - 1, this.curSlide + 1);
        this.transitionActive = true;
        setTimeout(() => { this.transitionActive = false; }, 300);
      } else if (event.deltaY < 0) {
        this.curSlide = Math.max(0, this.curSlide - 1);
        this.transitionActive = true;
        setTimeout(() => { this.transitionActive = false; }, 300);
      }
    };
    document.body.addEventListener('wheel', this._wheelListener);
  }

  beforeDestroy(): void {
    this._resizeObserver.disconnect();
  }

  @Watch('fullScreenSlides')
  updateFullScreenSlides(): void {
    // Bla
  }
}
