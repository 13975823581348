











import { Component, ProvideReactive, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  @ProvideReactive() prefersReducedMotion = false;

  created(): void {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    this.prefersReducedMotion = mediaQuery.matches;
  }
}
